.pilgrim-section {
  margin: 0 auto;
  padding: 1.25rem;
  text-align: center;
}

.pilgrim-section h1 {
  margin: 0;
  font-size: 2.125rem;
  font-weight: 800;
  line-height: 1.2;
  color: var(--primary-red);
}

.pilgrim-section h2 {
  margin-top: 0;
  font-size: 1.875rem;
  line-height: 0.1;
  letter-spacing: 0.625rem;
  color: var(--primary-red);
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 1.25rem;
  padding: 0.625rem 0;
  scrollbar-width: none;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.package-card {
  flex: 0 0 auto;
  border: 0.0625rem solid var(--border-light);
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  text-align: center;
}

.package-image {
  width: 23vw;
  height: 15vw;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.package-title {
  padding: 0.625rem;
  font-size: 1rem;
  color: var(--text-dark);
  font-weight: bold;
}

.scroll-buttons {
  margin-top: 0.625rem;
  display: flex;
  justify-content: center;
  gap: 0.625rem;
}

.scroll-btn {
  border: none;
  padding: 0.625rem 0.9375rem;
  font-size: 1.125rem;
  cursor: pointer;
  border-radius: 0.3125rem;
  background-color: var(--primary-red);
  color: white;
  transition: opacity 0.3s ease;
}

.scroll-btn.disabled {
  background-color: var(--disabled-bg);
  color: var(--disabled-text);
  cursor: not-allowed;
}

@media (max-width: 48rem) {
  .package-card {
    width: 47%;
    margin: 0 auto;
  }

  .package-image {
    display: block;
    width: fit-content;
    max-width: 100%;
    margin: 0 auto;
    height: 25vw;
  }
}