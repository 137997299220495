html {
  font-size: 60%;
}

@media (min-width: 576px) {
  html {
    font-size: 72.5%;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 78.75%;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 85%;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 95%;
  }
}

@media (min-width: 1400px) {
  html {
    font-size: 100%;
  }
}
