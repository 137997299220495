.baner-container {
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-red);
  padding: 2.5rem 3.75rem;
}

.baner-content {
  max-width: 45%;
  color: var(--white-color);
}

.baner-content h1 {
  margin: 0;
  font-size: 2.125rem;
  font-weight: 800;
  line-height: 1.2;
}

.baner-content h2 {
  margin-top: 0;
  font-size: 1.875rem;
  line-height: 0.1;
  letter-spacing: 0.625rem;
}

.baner-content p {
  font-size: 0.9375rem;
  letter-spacing: 0.00625rem;
  line-height: 1.3;
  font-weight: 600;
}

.baner-image {
  max-width: 50%;
}

.baner-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.bookings {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  padding: 3rem;
  justify-items: center;
}

.booking {
  width: 100%;
  text-align: center;
  background-color: var(--white-color);
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0.1875rem;
}

.booking-image {
  width: 100%;
  height: 12.5rem;
  object-fit: cover;
  border-bottom: 0.125rem solid #ddd;
}

.booking-heading {
  margin: 0.625rem 0;
  font-weight: 600;
  font-size: 1.25rem;
}

.booking-paragraph {
  font-size: 0.9375rem;
  color: var(--text-dark);
  padding: 0 0.9375rem;
  font-weight: 500;
}

.booking-button {
  background-color: var(--highlight-red);
  border: none;
  padding: 0.625rem 1.25rem;
  margin: 0.25rem;
  border-radius: 0.75rem;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}

.booking-button:hover {
  background-color: var(--secondary-red);
}

@media screen and (max-width: 48rem) {
  .bookings {
    grid-template-columns: 1fr;
  }
  .baner-container{
    display: block;
  }
  .baner-content{
    max-width: 95%;
    margin: auto;
  }
  .baner-image{
    max-width: 95%;
    margin: auto;
  }
}