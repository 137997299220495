.scrolling-text {
  white-space: nowrap;
  width: 100%; 
  font-size: 1.125rem;
  padding: 0.625rem 0;
  text-align: center;
  color: var(--secondary-red);
  font-weight: 700;
  position: relative; 
  overflow: hidden; 
}

.scrolling-text span {
  display: inline-block;
  white-space: nowrap; 
  animation: scroll-left 35s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%); 
  }
  100% {
    transform: translateX(-100%);
  }
}

.form-background {
  width: 100%;
  padding: 1.25rem 0;
  background-image: url('../images/car-bnr.jpg'); 
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  max-width: 100vw;
  overflow: hidden;
}

.form-container {
  background-color: rgba(0, 0, 0, 0.616);
  color: white;
  border: 0.1875rem solid var(--primary-red);
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  width: 40%;
  margin-left: 4.375rem;
}

.form-title {
  margin: 0;
  padding: 0.625rem;
  background-color: var(--primary-red);
  width: fit-content;
  color: white;
}

.enquiry-form {
  display: flex;
  flex-direction: column;
}

.form-input {
  margin: 0.625rem 1.25rem;
  padding: 0.625rem;
  border: none;
  background-color: transparent;
  color: white;
  outline: none;
  border-bottom: 0.09375rem solid #ffffff;
}

.enquire-button {
  background-color: var(--highlight-red);
  color: white;
  border: none;
  margin: 0.625rem auto;
  padding: 0.625rem 1.25rem;
  border-radius: 0.75rem;
  font-size: 1rem;
  cursor: pointer;
}

.enquire-button:hover {
  background-color: var(--primary-red);
}

@media screen and (max-width: 48rem) {
  .form-background {
    flex-direction: column; 
  }

  .form-container {
    width: 90%;
    margin: 1rem auto;
  }
}
