.header-container {
  font-size: 0.875rem;
  color: var(--secondary-red); 
}

.header-container a {
  text-decoration: none;
}

.top-bar {
  background-color: var(--primary-red);
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0.3125rem 4.375rem;
}

.top-links a, .top-links span {
  margin: auto 0.25rem;
  color: white;
  font-weight: 600;
  white-space: nowrap;
}

.top-contact span {
  margin: auto 0.375rem;
  white-space: nowrap;
}

.top-contact a{
  color: white;
  white-space: nowrap;
}

.heads {
  display: flex;
  justify-content: center;
  padding: 0.625rem;
}

.main-header {
  text-align: left;
  margin: auto 0;
}

.main-header h1 {
  font-size: 1.875rem;
  font-weight: bold;
  color: var(--secondary-red);
  margin-bottom: 0.375rem;
}

.main-header p {
  margin: 0;
  font-size: 0.9375rem;
  color: var(--secondary-red);
  font-weight: 600;
}

.blink {
  animation: blink-animation 2s infinite ease-in-out;
}

@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}

.nav-bar {
  display: flex;
  justify-content: center;
  padding: 0 0.625rem;
  font-size: 1.0625rem;
  margin: auto 0;
}

.nav-item {
  margin: 0 0.1875rem;
  color: var(--secondary-red);
  display: flex;
  padding: 0.625rem;
  align-items: center;
}

.nav-item:hover {
  color: white;
  background-color: var(--secondary-red);
}

.nav-selected {
  color: white;
  background-color: var(--secondary-red);
}

.nav-item i {
  margin-right: 0.3125rem;
}

.sticky-bottom-bar {
  display: none !important;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--primary-red);
  display: flex;
  justify-content: space-around;
  padding: 0.625rem 0;
  z-index: 1000;
}

.sticky-icon {
  color: var(--white-color);
  font-size: 2.2rem;
  text-decoration: none;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
}

.popover-nav {
  position: fixed;
  bottom: 3.75rem;
  right: 0;
  width: fit-content;
  background-color: var(--background-color);
  border-top: 1px solid var(--border-light);
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popover-nav .nav-item {
  display: block;
  padding: 0.5rem 1rem;
  color: var(--secondary-red);
  font-size: 1rem;
  text-decoration: none;
  width: fit-content;
  text-align: right;
}

.popover-nav .nav-item:hover {
  background-color: var(--highlight-red);
  color: var(--white-color);
}

@media screen and (max-width: 48rem) {
   .top-bar{
    padding: 0.5rem 0;
   }
   .nav-bar{
    display: none;
   }
   .sticky-bottom-bar{
    display: flex !important;
   }
   .mobView{
    display: none;
   }
}
