.about-section {
  margin: 0 auto;
  padding: 1.25rem;
  line-height: 1.6;
  color: var(--text-dark);
  text-align: center;
}

.about-section h1 {
  margin: 0;
  font-size: 2.125rem;
  font-weight: 800;
  line-height: 1.2;
  color: var(--primary-red);
}

.about-section h2 {
  margin-top: 0;
  font-size: 1.875rem;
  line-height: 0.1;
  letter-spacing: 0.625rem;
  color: var(--primary-red);
}

.toggle-button {
  background-color: var(--primary-red);
  border: none;
  padding: 0.625rem 0.9375rem;
  border-radius: 0.3125rem;
  cursor: pointer;
  font-size: 0.875rem;
  margin-top: 0.625rem;
}

.about-paragraph {
  font-size: 1rem;
}

.toggle-button:hover {
  background-color: var(--secondary-red);
}
