.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .close-modal-btn {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    background: transparent;
    border: none;
    font-size: 3rem;
    color: var(--text-dark);
    cursor: pointer;
}

  .modal-content {
    display: flex;
    position: relative;
    background-color: var(--background-color);
    padding: 1.25rem;
    border-radius: 0.5rem;
    max-width: 48rem;
    width: 90%;
    text-align: left;
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal-image {
    width: 47%;
    height: auto;
    border-radius: 0.5rem;
    margin-bottom: 1.25rem;
  }

  .modal-content h2 {
    text-align: center;
    line-height: 1 !important;
    letter-spacing: 0rem !important;
    color: var(--primary-red);
  }

  .modal-passage {
    width: 50%;
    margin: 10px;
    align-self: center;
  }

  .modal-content label {
    display: block;
    padding: 0 0.625rem;
    color: var(--text-dark);
  }

  .modal-content input {
    width: 100%;
    padding: 0.625rem;
    margin: 0.625rem;
    border: 0.0625rem solid var(--border-light);
    border-radius: 0.25rem;
    background-color: var(--background-color);
    color: var(--text-dark);
  }

  .submit-enquire {
    text-align: center;
  }

  .submit-enquire button {
    padding: 0.625rem 1.25rem;
    background-color: var(--primary-red);
    color: var(--white-color);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  .submit-enquire button:hover {
    background-color: var(--secondary-red);
  }

  @media (max-width: 48rem) {
    .modal-content {
      flex-direction: column; 
      align-items: center;
      text-align: center;
    }
  
    .modal-image {
      width: 100%; 
      max-height: 40vh;
      max-width: fit-content;
      margin-bottom: 1rem;
    }
  
    .modal-passage {
      width: 100%; 
      margin-top: 1rem;
    }
    .modal-content{
        margin-bottom: 3.75rem;
    }
  }