.domestic-section {
  margin: 0 auto;
  padding: 1.25rem;
}

.domestic-head {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 0.3125rem;
}

.domestic-head::after {
  content: '';
  display: block;
  width: 100%;
  height: 0.1875rem;
  background-color: var(--primary-red);
  margin: 0.625rem auto 0;
  position: absolute;
  bottom: 0;
}

.domestic-section h1 {
  margin: 0;
  font-size: 2.125rem;
  font-weight: 800;
  line-height: 1.2;
  color: var(--primary-red);
}

.domestic-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 1.4375rem;
  padding: 0.625rem 0;
  scrollbar-width: none;
}

.domestic-container::-webkit-scrollbar {
  display: none;
}

.domestic-card {
  flex: 0 0 auto;
  border: 0.0625rem solid var(--border-light);
  box-shadow: 0 0.4375rem 0.5625rem rgba(0, 0, 0, 0.1);
}

.domestic-image {
  width: 22vw;
  height: 15vw;
}

.domestic-title {
  padding: 0.625rem;
  font-size: 1rem;
  color: var(--text-dark);
  font-weight: bold;
}

.domestic-buttons {
  display: flex;
  justify-content: center;
}

.domestic-btn {
  padding: 0.3125rem 0.625rem;
  font-size: 1.125rem;
  cursor: pointer;
  border: 0.1875rem solid var(--primary-red);
  background-color: var(--primary-red);
  color: white;
  transition: opacity 0.3s ease;
}

.domestic-btn.disabled {
  background-color: var(--disabled-bg);
  color: var(--disabled-text);
  cursor: not-allowed;
}
@media screen and (max-width: 48rem) {
  .domestic-card {
    width: 47%;
    margin: 0 auto;
    text-align: center;
  }

  .domestic-image {
    display: block;
    width: fit-content;
    max-width: 100%;
    margin: 0 auto;
    height: 25vw;
  }
}